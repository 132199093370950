<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditScopeSetting />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditScopeSetting from '@/components/student/scope/EditScopeSetting.vue'
import Sidebar from '@/components/student/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    EditScopeSetting,
    Sidebar,
  },
}
</script>
